import React from 'react';
import {Dialog} from 'wix-ui-tpa';

export enum LogoutDialogDataHook {
  dialog = 'LogoutDialog.dialog',
}
interface LogoutDialogProps {
  show?: boolean;
}

export const LogoutDialog = ({show}: LogoutDialogProps) => {
  if (!show) {
    return null;
  }
  return <Dialog data-hook={LogoutDialogDataHook.dialog} isOpen={show}></Dialog>;
};
