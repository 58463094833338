/* istanbul ignore file: test forms */
import {FormValues} from '@wix/form-viewer';
import {ContactModel} from '../../../../domain/models/Contact.model';
import {CommonVatId, CommonVatType} from '@wix/ambassador-ecom-v1-checkout/types';

export const getVatFormInitialState = (contact?: ContactModel) => ({
  vat_id: {
    id: contact?.vatId?.id ?? '',
    type: contact?.vatId?.type ?? CommonVatType.UNSPECIFIED,
  },
});

export const getVatFromVatFormValues = (vatFormValues: FormValues): CommonVatId | undefined => {
  const vatId = vatFormValues.vat_id;
  if (typeof vatId === 'object' && !Array.isArray(vatId) && vatId !== null && vatId.id) {
    return {
      id: vatId.id as string,
      type: getCommonVatType(vatId.type as string),
    };
  }
  return undefined;
};

const getCommonVatType = (type: string): CommonVatType => {
  switch (type) {
    case 'CPF':
      return CommonVatType.CPF;
    case 'CNPJ':
      return CommonVatType.CNPJ;
  }
  return CommonVatType.UNSPECIFIED;
};
