import {FIELDS_GAP, FORMS_TEMPLATE_IDS} from '../../constants';
import {FormViewer} from '@wix/form-viewer/widget';
import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {FormProps} from '../ContactForm/ContactForm';

const EmailFormInternal = ({formRef, formValues, setFormValues, formErrors, setFormErrors}: FormProps) => {
  const {i18n} = useTranslation();
  const localeKeys = useLocaleKeys();

  return (
    <FormViewer
      i18n={i18n}
      ref={formRef}
      overrides={{
        email: {label: localeKeys.checkout.address_information.email_label()},
      }}
      formId={FORMS_TEMPLATE_IDS.EMAIL}
      values={formValues}
      errors={formErrors}
      onChange={setFormValues}
      onValidate={setFormErrors}
      style={{
        styleParams: {
          numbers: {
            rowSpacing: FIELDS_GAP,
          },
        },
      }}
    />
  );
};

export const EmailForm = React.memo(EmailFormInternal);
